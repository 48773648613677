import type {SVGProps} from 'react'
const SvgLike = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 512" {...props}>
    <path
      d="M112 448c0 8.8-7.2 16-16 16H32c-8.8 0-16-7.2-16-16V224c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16z"
      style={{
        fill: '#6495ed',
      }}
    />
    <path
      d="M512 229.5c0-30.4-24.1-53.5-53.8-53.5H329.3c20.9-36.5 30.7-74.9 30.7-90.1 0-26.5-20.6-53.9-55.1-53.9-67.6 0-32.7 76.4-108.1 136.8l-33.6 24.8c-2.1 1.5-4.1 4-4.1 6.4 0 5.9 5.3 7.9 7.9 8v192.1c-3.9.5-7 3.9-7 7.9 0 2.6 1.3 5.1 3.6 6.7l50.5 33.4c27.7 20.5 61.9 31.8 96.4 31.8h56c29.7 0 53.8-24 53.8-53.5 0-1.6-.1-3.3-.2-4.9 18.7-8.8 30.8-27.3 30.8-48.1 0-6.3-1.2-12.6-3.5-18.5 20.2-7.9 34-27.4 34-49.7 0-8.8-2.2-17.5-6.5-25.2 21.6-7 37.1-27.2 37.1-50.5"
      style={{
        fill: '#ffdead',
      }}
    />
    <path
      d="M96 191.1H32c-17.7 0-32 14.3-32 31.1v223.1c0 17.7 14.3 31.1 32 31.1h64c17.7 0 32-14.3 32-31.1V223.1c0-16.8-14.3-32-32-32M112 448c0 8.8-7.2 16-16 16H32c-8.8 0-16-7.2-16-16V224c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16zm400-218.5c0-30.4-24.1-53.5-53.8-53.5H329.3c20.9-36.5 30.7-74.9 30.7-90.1 0-26.5-20.6-53.9-55.1-53.9-67.6 0-32.7 76.4-108.1 136.8l-33.6 24.8c-2.1 1.5-4.1 4-4.1 6.4 0 6 5.4 8 8 8 1.7 0 3.3-.5 4.8-1.6l33.7-25C285.8 117.1 253.2 48 304.8 48c24.5 0 39.2 19.3 39.2 37.9 0 12.1-9 49.7-30.7 85.6l-5.3 8c-.9 1.3-1.3 2.9-1.3 4.4 0 4.6 3.8 8 8 8h143.5c20.8 0 37.8 16.8 37.8 37.5 0 19.5-15.5 35.8-35.2 37.2-4.2.3-7.5 3.8-7.5 8 0 7.2 12.1 11.2 12.1 30.6 0 18-13 33.5-30.8 36.8-2.8.5-6.5 3.1-6.5 7.9 0 5.1 6.8 9.6 6.8 23.6 0 16.2-10.5 30.4-26.2 35.5-3.4 1.1-5.5 4.2-5.5 7.6 0 2.2 1.2 4.5 1.2 10 0 20.7-17 37.5-37.8 37.5h-56c-31.1 0-61.9-10.2-87.2-29l-50.9-33.7c-1.4-.9-2.9-1.3-4.4-1.3-4.4 0-8 3.6-8 8 0 2.6 1.3 5.1 3.6 6.7l50.6 33.4c27.7 20.5 61.9 31.8 96.4 31.8h56c29.7 0 53.8-24 53.8-53.5 0-1.6-.1-3.3-.2-4.9 18.7-8.8 30.8-27.3 30.8-48.1 0-6.3-1.2-12.6-3.5-18.5 20.2-7.9 34-27.4 34-49.7 0-8.8-2.2-17.5-6.5-25.2 21.4-7.1 36.9-27.3 36.9-50.6"
      style={{
        fill: '#333',
      }}
    />
    <path
      fill="#333"
      d="M96 191.1H32c-17.7 0-32 14.3-32 31.1v223.1c0 17.7 14.3 31.1 32 31.1h64c17.7 0 32-14.3 32-31.1V223.1c0-16.8-14.3-32-32-32M112 448c0 8.8-7.2 16-16 16H32c-8.8 0-16-7.2-16-16V224c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16zm400-218.5c0-30.4-24.1-53.5-53.8-53.5H329.3c20.9-36.5 30.7-74.9 30.7-90.1 0-26.5-20.6-53.9-55.1-53.9-67.6 0-32.7 76.4-108.1 136.8l-33.6 24.8c-2.1 1.5-4.1 4-4.1 6.4 0 6 5.4 8 8 8 1.7 0 3.3-.5 4.8-1.6l33.7-25C285.8 117.1 253.2 48 304.8 48c24.5 0 39.2 19.3 39.2 37.9 0 12.1-9 49.7-30.7 85.6l-5.3 8c-.9 1.3-1.3 2.9-1.3 4.4 0 4.6 3.8 8 8 8h143.5c20.8 0 37.8 16.8 37.8 37.5 0 19.5-15.5 35.8-35.2 37.2-4.2.3-7.5 3.8-7.5 8 0 7.2 12.1 11.2 12.1 30.6 0 18-13 33.5-30.8 36.8-2.8.5-6.5 3.1-6.5 7.9 0 5.1 6.8 9.6 6.8 23.6 0 16.2-10.5 30.4-26.2 35.5-3.4 1.1-5.5 4.2-5.5 7.6 0 2.2 1.2 4.5 1.2 10 0 20.7-17 37.5-37.8 37.5h-56c-31.1 0-61.9-10.2-87.2-29l-50.9-33.7c-1.4-.9-2.9-1.3-4.4-1.3-4.4 0-8 3.6-8 8 0 2.6 1.3 5.1 3.6 6.7l50.6 33.4c27.7 20.5 61.9 31.8 96.4 31.8h56c29.7 0 53.8-24 53.8-53.5 0-1.6-.1-3.3-.2-4.9 18.7-8.8 30.8-27.3 30.8-48.1 0-6.3-1.2-12.6-3.5-18.5 20.2-7.9 34-27.4 34-49.7 0-8.8-2.2-17.5-6.5-25.2 21.4-7.1 36.9-27.3 36.9-50.6"
      className="like_svg__gray"
    />
  </svg>
)
export default SvgLike
