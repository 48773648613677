import type {SVGProps} from 'react'
const SvgGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={539} height={348} fill="none" {...props}>
    <mask
      id="group_svg__a"
      width={539}
      height={348}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <rect width={539} height="100%" fill="#1A817C" />
    </mask>
    <g mask="url(#group_svg__a)">
      <g filter="url(#group_svg__b)">
        <ellipse cx={160} cy={220.266} fill="#1A817C" rx={40} ry={40.231} />
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M160 208.201a3.99 3.99 0 0 0-3.692 2.481 4.03 4.03 0 0 0 0 3.076 3.997 3.997 0 0 0 3.692 2.481c1.06 0 2.076-.424 2.825-1.177a4.03 4.03 0 0 0 1.171-2.842 4.03 4.03 0 0 0-1.171-2.842 3.98 3.98 0 0 0-2.825-1.177m-.764 2.164a1.985 1.985 0 0 1 2.175.435 2.01 2.01 0 0 1 0 2.839 1.987 1.987 0 0 1-2.823 0 2 2 0 0 1-.584-1.419 2.01 2.01 0 0 1 1.232-1.855M154.591 218.839a2 2 0 0 1 1.409-.585h8c.53 0 1.039.212 1.414.59.375.377.586.888.586 1.422v6.035a6.03 6.03 0 0 1-2.865 5.141 5.97 5.97 0 0 1-4.072.813 6 6 0 0 1-3.623-2.038 6.06 6.06 0 0 1-1.44-3.916v-6.049a2.02 2.02 0 0 1 .591-1.413m9.409 7.462c0 .422-.066.841-.194 1.243a4.02 4.02 0 0 1-1.715 2.182 3.99 3.99 0 0 1-5.131-.817 4.04 4.04 0 0 1-.96-2.61v-6.033h8zM169 210.208c-.796 0-1.559.318-2.121.884a3.025 3.025 0 0 0 0 4.267 2.99 2.99 0 0 0 4.242 0 3.025 3.025 0 0 0 0-4.267 3 3 0 0 0-2.121-.884m-.707 2.306a.998.998 0 0 1 1.707.712.998.998 0 1 1-2 0c0-.267.105-.523.293-.712M174 220.266v5.029c0 .823-.201 1.634-.585 2.361a5 5 0 0 1-1.618 1.809 4.98 4.98 0 0 1-4.669.495q.319-.63.527-1.314c.224-.742.345-1.53.345-2.345v-6.035c0-.734-.196-1.42-.536-2.012H172c.53 0 1.039.212 1.414.59.375.377.586.888.586 1.422m-3.322 7.532a3 3 0 0 1-.856.4q.177-.924.178-1.897v-6.035h2v5.03a3.02 3.02 0 0 1-1.322 2.502M153.121 211.092a2.99 2.99 0 0 0-4.242 0 3.025 3.025 0 0 0 0 4.267 2.99 2.99 0 0 0 4.242 0 3.025 3.025 0 0 0 0-4.267M151 212.22a1 1 0 0 1 1 1.006.998.998 0 1 1-2 0 .998.998 0 0 1 1-1.006M146 225.295v-5.029c0-.534.211-1.045.586-1.422.375-.378.884-.59 1.414-.59h4.536a4 4 0 0 0-.536 2.012v6.035a8.1 8.1 0 0 0 .872 3.659 5 5 0 0 1-2.396.338 5 5 0 0 1-2.273-.833 5.045 5.045 0 0 1-2.203-4.17m4.178 2.903a3 3 0 0 1-1.827-1.486 3 3 0 0 1-.351-1.416v-5.03h2v6.035q.001.973.178 1.897"
        clipRule="evenodd"
      />
      <path
        stroke="#A6D9D7"
        d="M239.5 220.266c0 44.165-35.596 79.962-79.5 79.962s-79.5-35.797-79.5-79.962 35.596-79.963 79.5-79.963 79.5 35.798 79.5 79.963Z"
      />
      <path
        stroke="#A6D9D7"
        d="M279.5 220.266c0 66.384-53.505 120.194-119.5 120.194S40.5 286.65 40.5 220.266 94.005 100.072 160 100.072s119.5 53.81 119.5 120.194Z"
      />
      <path
        stroke="#A6D9D7"
        d="M319.5 220.266c0 88.603-71.413 160.425-159.5 160.425S.5 308.869.5 220.266 71.913 59.841 160 59.841s159.5 71.822 159.5 160.425Z"
      />
      <g filter="url(#group_svg__c)">
        <ellipse cx={379} cy={62.358} fill="#1A817C" rx={40} ry={40.231} />
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M385 54.312c0 3.333-2.686 6.035-6 6.035s-6-2.702-6-6.035 2.686-6.034 6-6.034 6 2.701 6 6.034m-2 0c0 2.222-1.791 4.023-4 4.023s-4-1.8-4-4.023 1.791-4.023 4-4.023 4 1.801 4 4.023M389 76.44c1.105 0 2-.901 2-2.012 0-6.666-5.373-12.07-12-12.07s-12 5.404-12 12.07c0 1.11.895 2.011 2 2.011zm-10-12.07c-4.838 0-8.873 3.455-9.8 8.046q-.198.977-.2 2.012h20q-.002-1.036-.2-2.012c-.927-4.59-4.962-8.046-9.8-8.046"
        clipRule="evenodd"
      />
      <path
        stroke="#A6D9D7"
        d="M458.5 62.358c0 44.165-35.596 79.963-79.5 79.963s-79.5-35.798-79.5-79.963 35.596-79.962 79.5-79.962 79.5 35.798 79.5 79.962Z"
      />
      <path
        stroke="#A6D9D7"
        d="M498.5 62.358c0 66.384-53.505 120.194-119.5 120.194s-119.5-53.81-119.5-120.194c0-66.383 53.505-120.193 119.5-120.193S498.5-4.025 498.5 62.358Z"
      />
      <path
        stroke="#A6D9D7"
        d="M538.5 62.358c0 88.603-71.413 160.425-159.5 160.425S219.5 150.961 219.5 62.358 290.913-98.067 379-98.067 538.5-26.245 538.5 62.358Z"
      />
    </g>
    <defs>
      <filter
        id="group_svg__b"
        width={128}
        height={128.462}
        x={96}
        y={172.035}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={16} />
        <feGaussianBlur stdDeviation={12} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.12549 0 0 0 0 0.172549 0 0 0 0 0.34902 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1264_4856" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1264_4856" result="shape" />
      </filter>
      <filter
        id="group_svg__c"
        width={128}
        height={128.462}
        x={315}
        y={14.127}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={16} />
        <feGaussianBlur stdDeviation={12} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.12549 0 0 0 0 0.172549 0 0 0 0 0.34902 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1264_4856" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1264_4856" result="shape" />
      </filter>
    </defs>
  </svg>
)
export default SvgGroup
