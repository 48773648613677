import { Link } from '@remix-run/react';
import { ArrowUpRight } from '~/components/icons-generated';
import { questionUrl } from '~/routesMapper';
import styles from './listTable.module.css';

export type ListItem = {
  pageid: string;
  title: string;
  subtitle?: string;
  hasIcon?: boolean;
  className?: string;
};
export type ListTableProps = {
  /**
   * Browse by category
   */
  elements: ListItem[];
  sameTab?: boolean;
  className?: string;
};

export const ListTable = ({ elements, sameTab, className }: ListTableProps) => {
  if (!elements || elements.length === 0) return null;
  return (
    <div className={styles.container + ' bordered' + (className ? ' ' + className : '')}>
      {elements.map(({ pageid, title, subtitle, hasIcon, className }, i) =>
      <Link
        key={`entry-${i}`}
        className={styles.entry + ' teal-500 flex-container large-bold ' + (className || '')}
        to={questionUrl({ pageid, title })}
        rel={sameTab ? undefined : 'noopener noreferrer'}>

          <div>
            <div>{title}</div>
            {subtitle && <div className="grey subtitle">{subtitle}</div>}
          </div>
          {hasIcon && <ArrowUpRight className="vertically-centered" />}
        </Link>
      )}
    </div>);

};
export default ListTable;