import type {SVGProps} from 'react'
const SvgPiggyBank = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" {...props}>
    <path
      fill="url(#piggy_bank_svg__a)"
      fillRule="evenodd"
      d="M18.571 8.571a2.857 2.857 0 0 1 2.857-2.857h.169a7.14 7.14 0 0 1 6.976 5.606 1.96 1.96 0 0 0 1.911 1.537h23.802c4.297 0 8.278 1.355 11.538 3.662 1.108.784 2.747.553 2.747-.805a4.286 4.286 0 0 0-4.285-4.286 2.857 2.857 0 0 1 0-5.714c5.522 0 10 4.477 10 10a9.95 9.95 0 0 1-1.41 5.123c-.694 1.16-.886 2.61-.333 3.843a19.9 19.9 0 0 1 1.743 8.177v10c0 7.332-3.683 13.804-9.3 17.668-1.709 1.175-3.132 2.79-3.67 4.794l-.607 2.252a7.14 7.14 0 0 1-6.898 5.286h-2.383A2.857 2.857 0 0 1 48.572 70v-2.714a3 3 0 0 0-3-3h-5.428a3 3 0 0 0-3 3V70a2.857 2.857 0 0 1-2.857 2.857h-2.383a7.14 7.14 0 0 1-6.898-5.286c-.653-2.427-2.6-4.23-4.793-5.458a17.2 17.2 0 0 1-5.895-5.444 3.3 3.3 0 0 0-.48-.572l-6.842-6.414a8.57 8.57 0 0 1-2.71-6.254v-12a2.857 2.857 0 0 1 2.858-2.858h1.285c1.657 0 2.97-1.355 3.283-2.982a15.7 15.7 0 0 1 5.547-9.234c.788-.639 1.313-1.568 1.313-2.582zm15.715 15.715a2.857 2.857 0 0 1 2.857-2.858H48.57a2.857 2.857 0 0 1 0 5.715H37.143a2.857 2.857 0 0 1-2.857-2.857"
      clipRule="evenodd"
      style={{
        mixBlendMode: 'multiply',
      }}
    />
    <circle cx={42.857} cy={12.857} r={10.929} fill="#fff" fillOpacity={0.8} stroke="#7AC0BE" />
    <defs>
      <linearGradient
        id="piggy_bank_svg__a"
        x1={74.58}
        x2={-8.739}
        y1={72.44}
        y2={40.027}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#17736E" />
        <stop offset={1} stopColor="#7AC0BE" />
      </linearGradient>
    </defs>
  </svg>
)
export default SvgPiggyBank
