import type {SVGProps} from 'react'
const SvgFollowup = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 512" {...props}>
    <path
      d="M256 431.1c-35.1 0-69.4-6.2-101.8-18.4l-8.5-2.3-7.1 5.7c-20.2 16.1-60.1 42-112.7 47.1 14.4-18.3 37.4-51.7 46.6-88.6l2.2-8.8-6.4-6.4C34.1 325.1 16 283.8 16 239.1c0-105.9 107.7-192 240-192s240 86.1 240 192-107.7 192-240 192"
      style={{
        fill: '#ffffe0',
      }}
    />
    <path
      d="M283.7 127.6h-60.6c-30.4 0-55.1 24.7-55.1 55 0 4.4 3.6 8 8 8s8-3.6 8-8c0-21.5 17.5-39.1 39.1-39.1h60.6c24.4 0 44.3 19.9 44.3 44.3 0 16.9-9.4 32.1-24.6 39.7L252.3 254c-2.6 1.4-4.3 4.2-4.3 7.1V288c0 4.4 3.6 8 8 8s8-3.6 8-8v-21.9l46.7-24.2c20.5-10.3 33.3-31 33.3-54 0-33.3-27.1-60.3-60.3-60.3M256 328c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16m0-296.9C114.6 31.1.9 124.2.9 239.1c0 49.6 21.4 95 57 130.7-12.5 50.4-54.3 95.3-54.8 95.8-2.2 2.3-2.8 5.7-1.5 8.7s4.1 4.8 7.3 4.8c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 255.1-93.1 255.1-208s-114.6-208-256-208m0 400c-35.1 0-69.4-6.2-101.8-18.4l-8.5-2.3-7.1 5.7c-20.2 16.1-60.1 42-112.7 47.1 14.4-18.3 37.4-51.7 46.6-88.6l2.2-8.8-6.4-6.4C34.1 325.1 16 283.8 16 239.1c0-105.9 107.7-192 240-192s240 86.1 240 192-107.7 192-240 192"
      className="followup_svg__st1 followup_svg__gray"
    />
  </svg>
)
export default SvgFollowup
