import type {SVGProps} from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 512" {...props}>
    <path
      d="m328.1 92.8 91.1 91.1 66.9-66.9c12.5-12.5 12.5-32.7 0-45.2L440.2 26c-12.5-12.5-32.7-12.5-46.1 0z"
      style={{
        fill: '#ff69b4',
      }}
    />
    <path
      d="M316.8 104.1 73.6 347.3c-10.4 10.4-18 23.2-22.2 37.4L19.8 492.2l107.5-31.6c14.2-4.2 27-11.8 37.4-22.2l243.2-243.2z"
      style={{
        fill: 'gold',
      }}
    />
    <path
      d="M497.4 60.5c18.7 18.7 18.7 49.1 0 67.8L176 449.7c-12.3 11.4-27.5 21.3-44.2 26.2L10.3 511.7c-2.8.8-5.8 0-7.9-2-2.1-2.1-2.8-5.1-2-8l35.8-121.5c4.9-16.7 13.9-31.9 26.2-45.1L383.7 14.6c18.7-18.8 49.1-18.8 67.8 0zM328.1 92.8l91.1 91.1 66.9-66.9c12.5-12.5 12.5-32.7 0-45.2L440.2 26c-12.5-12.5-32.7-12.5-46.1 0zm-11.3 11.3L73.6 347.3c-10.4 10.4-18 23.2-22.2 37.4L19.8 492.2l107.5-31.6c14.2-4.2 27-11.8 37.4-22.2l243.2-243.2z"
      className="edit_svg__gray"
    />
  </svg>
)
export default SvgEdit
