import type {SVGProps} from 'react'
const SvgAisafety = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 175 40" {...props}>
    <g clipPath="url(#aisafety_svg__a)">
      <path
        fill="url(#aisafety_svg__b)"
        d="M81.697 32.615V21.634h-2.351V18.88h2.351v-5.805q0-2.986 1.325-4.579 1.358-1.592 3.974-1.592.696 0 1.524.199a4 4 0 0 1 1.49.663L88.72 9.99a1.5 1.5 0 0 0-.663-.398 2.1 2.1 0 0 0-.728-.133q-1.226 0-1.888.83-.663.83-.663 2.82v5.772h4.34v2.754h-4.34v10.982z"
      />
      <path
        fill="url(#aisafety_svg__c)"
        d="M59.343 12.909a17.4 17.4 0 0 0-2.683-1.095 9.4 9.4 0 0 0-2.848-.464q-1.92 0-3.08.895-1.125.896-1.126 2.389 0 1.128.728 1.924.729.763 1.921 1.327t2.484 1.062q1.126.43 2.22.962 1.093.53 1.953 1.327a5.45 5.45 0 0 1 1.391 1.957q.53 1.161.53 2.887 0 1.89-.96 3.45-.928 1.56-2.683 2.455-1.755.896-4.173.896-1.59 0-3.047-.365a15.6 15.6 0 0 1-2.65-.929 20 20 0 0 1-2.185-1.228l1.424-2.521q.795.564 1.821 1.095a14 14 0 0 0 2.153.83 8.5 8.5 0 0 0 2.186.298q1.125 0 2.219-.398a4.5 4.5 0 0 0 1.855-1.294q.728-.862.728-2.29 0-1.227-.662-2.023-.63-.83-1.69-1.393a17.6 17.6 0 0 0-2.251-1.062 62 62 0 0 1-2.286-.929 12.2 12.2 0 0 1-2.152-1.26 5.8 5.8 0 0 1-1.59-1.859q-.597-1.094-.596-2.654 0-1.89.894-3.284.927-1.427 2.517-2.223 1.59-.829 3.643-.896 2.319 0 4.074.564 1.788.563 3.146 1.394z"
      />
      <path fill="url(#aisafety_svg__d)" d="M136.74 13.235h-1.558v2.893h1.558z" />
      <path fill="url(#aisafety_svg__e)" d="M131.288 29.31h-3.301v3.306h3.301z" />
      <path
        fill="url(#aisafety_svg__f)"
        fillRule="evenodd"
        d="M65.504 32.118q1.59.83 3.51.83 1.193 0 2.153-.333a7.2 7.2 0 0 0 1.69-.895q.762-.531 1.192-1.029.15-.172.265-.322v2.246h3.08V18.88h-2.848l-.224 2.187q-.685-1.066-1.896-1.855-1.324-.896-3.246-.896-1.92 0-3.544.896-1.59.896-2.55 2.588-.96 1.659-.96 3.948 0 2.322.894 3.948.927 1.593 2.484 2.422m6.425-2.157q-.927.432-2.086.432-1.226 0-2.286-.63a4.7 4.7 0 0 1-1.689-1.726 4.8 4.8 0 0 1-.629-2.422q0-1.425.596-2.488.63-1.061 1.656-1.659a4.4 4.4 0 0 1 2.252-.597q1.127 0 2.054.431a4.35 4.35 0 0 1 1.59 1.161q.661.73.927 1.726v2.687q-.232 1.095-.861 1.891a4.1 4.1 0 0 1-1.524 1.194"
        clipRule="evenodd"
      />
      <path
        fill="url(#aisafety_svg__g)"
        fillRule="evenodd"
        d="M92.542 32.018q1.69.93 4.04.93a7.6 7.6 0 0 0 2.485-.432 9.4 9.4 0 0 0 2.318-1.128l-1.424-2.29a7 7 0 0 1-1.59.863 4.6 4.6 0 0 1-1.722.332q-1.325 0-2.385-.597a4.6 4.6 0 0 1-1.557-1.578l10.864-3.83q-.63-2.787-2.418-4.38-1.788-1.591-4.504-1.592-2.12 0-3.842.996a7.7 7.7 0 0 0-2.749 2.654q-1.026 1.659-1.026 3.682 0 2.123.894 3.782.927 1.626 2.616 2.588m-.451-5.771q-.045-.37-.046-.765 0-1.36.563-2.421a4.2 4.2 0 0 1 1.557-1.66q.993-.63 2.252-.63 1.491 0 2.418.83.855.735 1.26 1.725z"
        clipRule="evenodd"
      />
      <path
        fill="url(#aisafety_svg__h)"
        d="M106.602 12.842h3.08v6.105h3.323l-.031-.067h3.477l4.418 9.805 4.359-9.805h2.848l-8.843 20.338h-3.047l3.126-7.032-5.854-12.289v1.472h-3.776v11.246h-3.08V21.37h-2.517v-2.422h2.517z"
      />
      <path fill="url(#aisafety_svg__i)" d="M135.182 19.644h1.556v12.972h-1.556z" />
      <path
        fill="url(#aisafety_svg__j)"
        d="m141.446 22.53-.117-2.887h-1.358v12.972h1.557v-6.967q0-1.493.662-2.62a4.96 4.96 0 0 1 1.822-1.759q1.125-.63 2.417-.63 1.458.033 2.219.863.762.828.795 2.09v9.024h1.59V23.16q-.033-1.725-1.159-2.787-1.126-1.094-2.948-1.094-1.92 0-3.511.995-1.368.846-1.969 2.256"
      />
      <path
        fill="url(#aisafety_svg__k)"
        d="M155.131 32.615V21.103h-2.253v-1.426h2.253V13.14q0-3.118 1.059-4.479 1.094-1.393 3.213-1.393.463 0 .993.133.53.1.961.364l-.662 1.128a1.45 1.45 0 0 0-.53-.232 1.8 1.8 0 0 0-.63-.1q-1.424 0-2.152.996-.696.996-.696 3.55v6.569h4.339v1.426h-4.339v11.513z"
      />
      <path
        fill="url(#aisafety_svg__l)"
        fillRule="evenodd"
        d="M161.508 22.596q-.894 1.56-.894 3.45 0 1.991.894 3.55a6.87 6.87 0 0 0 2.484 2.422q1.557.863 3.544.863 1.954 0 3.511-.896a6.7 6.7 0 0 0 2.484-2.488q.894-1.56.894-3.45 0-1.892-.894-3.45a6.55 6.55 0 0 0-2.451-2.456q-1.557-.93-3.544-.929-1.954 0-3.544.93a6.8 6.8 0 0 0-2.484 2.454m1.358 6.27q-.695-1.26-.695-2.786 0-1.427.695-2.688a5.54 5.54 0 0 1 1.888-2.023q1.225-.797 2.749-.797 1.49 0 2.683.763a5.2 5.2 0 0 1 1.921 1.991q.728 1.227.728 2.754a5.5 5.5 0 0 1-.728 2.786 5.3 5.3 0 0 1-1.921 1.958q-1.193.73-2.683.73a5.2 5.2 0 0 1-2.716-.73 5.6 5.6 0 0 1-1.921-1.958"
        clipRule="evenodd"
      />
      <path
        fill="url(#aisafety_svg__m)"
        fillRule="evenodd"
        d="M2.387 34.464c0-1.277-1.064-2.311-2.376-2.311v-2.627c1.312 0 2.376-1.035 2.376-2.311S1.323 24.904.01 24.904v-2.627c1.312 0 2.376-1.035 2.376-2.311S1.323 17.655.01 17.655v-2.627c1.312 0 2.376-1.035 2.376-2.31 0-1.277-1.064-2.312-2.376-2.312V7.779c1.312 0 2.376-1.035 2.376-2.311S1.323 3.157.01 3.157V.035H3.22c0 1.276 1.063 2.311 2.375 2.311S7.97 1.311 7.97.036h2.7c0 1.275 1.064 2.31 2.376 2.31S15.42 1.311 15.42.036h2.7c0 1.275 1.063 2.31 2.375 2.31s2.376-1.035 2.376-2.31h2.7c0 1.275 1.063 2.31 2.375 2.31s2.375-1.035 2.376-2.31h2.699c0 1.275 1.064 2.31 2.376 2.31s2.375-1.035 2.375-2.31h3.209v3.12c-1.312 0-2.375 1.036-2.375 2.312s1.063 2.311 2.375 2.311v2.627c-1.312 0-2.375 1.035-2.375 2.311s1.063 2.311 2.375 2.311v2.627c-1.312 0-2.375 1.034-2.375 2.31s1.063 2.312 2.375 2.312v2.627c-1.312 0-2.375 1.034-2.375 2.31s1.063 2.312 2.375 2.312v2.626c-1.312 0-2.375 1.035-2.375 2.312s1.063 2.31 2.375 2.31v3.123h-3.21c0-1.277-1.063-2.311-2.374-2.311s-2.376 1.034-2.376 2.31h-2.7c0-1.276-1.063-2.31-2.375-2.31s-2.375 1.034-2.375 2.31h-2.7c0-1.276-1.064-2.31-2.376-2.31s-2.375 1.034-2.375 2.31h-2.7c0-1.276-1.063-2.31-2.375-2.31s-2.375 1.034-2.375 2.31h-2.7c0-1.276-1.064-2.31-2.376-2.31S3.22 38.62 3.22 39.896H.01v-3.121c1.312 0 2.376-1.035 2.376-2.311M33.9 8.592h-5.506v23.68h5.506zM16.779 7.66 6.135 32.273h4.585l1.907-4.634h7.848l1.82 4.634h5.386L17.036 7.659zm2.154 16.055-2.289-5.829-2.4 5.83z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <linearGradient
        id="aisafety_svg__b"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__c"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__d"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__e"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__f"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__g"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__h"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__i"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__j"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__k"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__l"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <linearGradient
        id="aisafety_svg__m"
        x1={72.996}
        x2={135.313}
        y1={84.988}
        y2={-48.471}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F0668" />
        <stop offset={0.312} stopColor="#12A391" />
        <stop offset={1} stopColor="#4F0668" />
      </linearGradient>
      <clipPath id="aisafety_svg__a">
        <path fill="#fff" d="M0 0h174.545v40H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgAisafety
